import React, {CSSProperties, FC} from "react";
import {DifferenceContextMenu} from "../DifferenceContextMenu/DifferenceContextMenu";
import {getStoreUtils} from "../../store/utils";
import {setDifferenceStatus} from "../../ducks/montitoring";
import {CommentInputIdPrefix} from "../DifferenceListSidebar/CommentInput";

type Props = {
    indexFromApi: number;
    id: string;
    styles: CSSProperties;
    classNameString: string;
    onMouseDown: (event: React.MouseEvent) => void;
    isImportant: boolean;
}
export const DifferenceComponent: FC<Props> = ({styles, id, classNameString, onMouseDown, isImportant, indexFromApi}) => {
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const {dispatch} = getStoreUtils();
    const onClose = () => {
        setContextMenu(null);
    };
    const onSetCommentHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const element = document.getElementById(`${CommentInputIdPrefix}${indexFromApi}`);
        setTimeout(() => {
            element.click();
            element.focus();
        }, 10);
        onClose();
    };
    const onSetIsImportantHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(setDifferenceStatus(indexFromApi, {
            important: !isImportant,
        }));
        onClose();
    };
    const onContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 5,
                    mouseY: event.clientY + 15,

                }
                : null,
        );
    }

    return <>
        <div
            onContextMenu={onContextMenu}
            id={id}
            onMouseDown={onMouseDown}
            style={styles}
            className={classNameString}
        />
        <DifferenceContextMenu
            isImportant={isImportant}
            setImportantHandler={onSetIsImportantHandler}
            setCommentHandler={onSetCommentHandler}
            onClose={onClose}
            open={(contextMenu !== null)}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                    : undefined
            }
        />
    </>
}
