import {Button, IconButton} from "@mui/material";
import {setDifferenceCommentPopup} from "../../ducks/montitoring";

import styles from "./DifferencePopupComment.module.scss";
import React, {FC} from "react";
import {CommentInput} from "../DifferenceListSidebar/CommentInput";
import {useSelector} from "react-redux";
import {selectDifferenceComment} from "../../selectors/monitoring";
import {getStoreUtils} from "../../store/utils";


export const DifferencePopupComment: FC = () => {
    const {dispatch} = getStoreUtils();
    const popupState = useSelector(selectDifferenceComment);
    const isOpen = !!popupState;
    const onClose = () => {
        dispatch(setDifferenceCommentPopup(undefined));
    };

    return isOpen? (
        <div
            onClick={onClose}
            className={styles.popupBack}>
            <div
                onClick={(e)=>{
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
                className={styles.popupContainer}
            >
                <div className={styles.messageContainer}>
                    <IconButton
                        onClick={onClose}
                        className={styles.closeIcon}
                    >
                    </IconButton>
                    <CommentInput
                        minRows={4}
                        label={''}
                        placeholder={'Добавить комментарий'}
                        indexFromApi={popupState?.indexFromApi}
                        comment={popupState?.comment}
                        detailedDiff={popupState?.detailedDiff}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        variant={'contained'}
                        className={styles.buttonCancel}
                        onClick={onClose}
                    >
                        Очистить
                    </Button>
                    <Button
                        className={`${styles.buttonAction}`}
                        onClick={() => {

                        }}
                        variant={'contained'}
                    >
                        Сохранить
                    </Button>

                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
