import React, {FC} from "react";
import {withStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu, {MenuProps} from "@mui/material/Menu";

type Props = {
    isImportant: boolean;
    setCommentHandler: (event: React.MouseEvent) => void;
    setImportantHandler: (event: React.MouseEvent) => void;
} & MenuProps;


const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        fontWeight: 500,
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #000000',
        boxShadow: "none",
        width: '100%',
        maxWidth: '200px',
        //position: 'static'
    },
    muiList: {
        padding: 0,
    }
})(Menu);

export const DifferenceContextMenu: FC<Props> = (
    {
        isImportant,
        setCommentHandler,
        setImportantHandler,
        ...props
    }) => {
    return <StyledMenu
        {...props}
    >
        <StyledMenuItem onClick={setCommentHandler}>
            добавить примечание
        </StyledMenuItem>
        <StyledMenuItem onClick={setImportantHandler}>
            {isImportant ? 'расхождение не важное' : 'отметить как важное'}
        </StyledMenuItem>
    </StyledMenu>
}
