import {Thunk, TypedAction} from "../store/types";
import {
    selectFirstDocumentFiles,
    selectPackageFromStorage,
    selectSecondDocumentFiles,
    selectTechReportText,
    selectUploadPackage
} from "../selectors/upload";
import {getUploadApiObj, IConvertedImage, IImagesByPackageId, IPair, PairDocs} from "../api/uploadApi";
import {PAIR_STORAGE_NAME, setLocaleStorageItem} from "../helper/other";
import {popupPush, spinnerMinusStatus, spinnerPlusStatus} from "./popup";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";
import {getMonitoringApiObj, ITechReport} from "../api/monitoringApi";
import {addMockPair} from "./montitoring";

export type PairUploading = {
    firstDocument: IFileFromInput[];
    secondDocument: IFileFromInput[];
};

const UPDATE_UPLOAD_FIRST_DOCUMENT = "UPLOAD/UPDATE_UPLOAD_FIRST_DOCUMENT";
const UPDATE_UPLOAD_SECOND_DOCUMENT = "UPLOAD/UPDATE_UPLOAD_SECOND_DOCUMENT";
const UPDATE_UPLOAD_FILES = "UPLOAD/UPDATE_UPLOAD_FILES";
const UPDATE_TECH_REPORT_TEXT = "UPLOAD/UPDATE_TECH_REPORT_TEXT";
const SET_DETAILED_UPLOAD = "UPLOAD/SET_DETAILED_UPLOAD";
const SET_UPLOAD_IS_LOADING = "UPLOAD/SET_UPLOAD_IS_LOADING";
const SET_UPLOAD_PAGE_COUNT = "UPLOAD/SET_UPLOAD_PAGE_COUNT";
const SET_DETAILED_FILE_PAGE = "UPLOAD/SET_DETAILED_FILE_PAGE";
const SET_SELECTED_FILE_IDS = "UPLOAD/SET_SELECTED_FILE_IDS";
const SET_UPLOAD_POPUP_OPEN = "UPLOAD/SET_UPLOAD_POPUP_OPEN";
const SET_FIRST_DOCUMENT_LOADING = "UPLOAD/SET_FIRST_DOCUMENT_LOADING";
const SET_SECOND_DOCUMENT_LOADING = "UPLOAD/SET_SECOND_DOCUMENT_LOADING";
const UPDATE_UPLOAD_PACKAGE = "UPLOAD/UPDATE_UPLOAD_PACKAGE";
const SHOW_PREVIEW_PAGES = "UPLOAD/SHOW_PREVIEW_PAGES";
const SET_COMMENT_POPUP_OPEN = "UPLOAD/SET_COMMENT_POPUP_OPEN";
export const SET_COMMENT = "SET_COMMENT";
export const SET_IS_SNACKBAR_OPEN = "SET_IS_SNACKBAR_OPEN";
export const SET_UPLOAD_POPUP_BACKGROUND = "SET_UPLOAD_POPUP_BACKGROUND";


export type UploadActions =
    | TypedAction<typeof UPDATE_UPLOAD_FILES, IFileFromInput[]>
    | TypedAction<typeof UPDATE_UPLOAD_FIRST_DOCUMENT, IFileFromInput[]>
    | TypedAction<typeof UPDATE_UPLOAD_SECOND_DOCUMENT, IFileFromInput[]>
    | TypedAction<typeof SET_UPLOAD_PAGE_COUNT, number>
    | TypedAction<typeof SET_DETAILED_UPLOAD, IFileFromInput>
    | TypedAction<typeof SET_UPLOAD_IS_LOADING, boolean>
    | TypedAction<typeof SHOW_PREVIEW_PAGES, boolean>
    | TypedAction<typeof UPDATE_UPLOAD_PACKAGE, IPair | undefined>
    | TypedAction<typeof SET_UPLOAD_POPUP_OPEN, boolean>
    | TypedAction<typeof SET_COMMENT_POPUP_OPEN, boolean>
    | TypedAction<typeof SET_SECOND_DOCUMENT_LOADING, boolean>
    | TypedAction<typeof SET_FIRST_DOCUMENT_LOADING, boolean>
    | TypedAction<typeof SET_SELECTED_FILE_IDS, string>
    | TypedAction<typeof UPDATE_TECH_REPORT_TEXT, string>
    | TypedAction<typeof SET_IS_SNACKBAR_OPEN, boolean>
    | TypedAction<typeof SET_DETAILED_FILE_PAGE, IDetailedDocumentPage | undefined>
    | TypedAction<typeof SET_COMMENT, string>
    | TypedAction<typeof SET_UPLOAD_POPUP_BACKGROUND, boolean>;

export interface IUploadState {
    files: IFileFromInput[];
    firstDocumentFiles: IFileFromInput[];
    secondDocumentFiles: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    selectedIds: string[];
    isLoading: boolean;
    showPreviewPages: boolean;
    commentPopupOpen: boolean;
    firstDocumentLoading: boolean;
    secondDocumentLoading: boolean;
    page: IDetailedDocumentPage | undefined;
    isUploadPopupOpen: boolean;
    pair: undefined;
    reportText: string;
    comment: string;
    isSnackbarOpen: boolean;
    isUploadPopupBackground: boolean;
}

export interface IDetailedDocumentPage {
    scale: number;
    rotate: number;
}

export interface IFileFromInput {
    id: string;
    fileUrl: string;
    fileName: string;
    imageId: number;
    mimeType: string;
}

export interface IDetailedFile extends IFileFromInput {
    index: number;
}

export const initialState: IUploadState = {
    files: [],
    page: {
        scale: 1,
        rotate: 0,
    },
    selectedIds: [],
    detailedFile: undefined,
    pageCount: 0,
    isLoading: true,
    showPreviewPages: true,
    isUploadPopupOpen: false,
    pair: undefined,
    reportText: "",
    firstDocumentLoading: false,
    secondDocumentLoading: false,
    firstDocumentFiles: [],
    secondDocumentFiles: [],
    comment: "",
    commentPopupOpen: false,
    isSnackbarOpen: false,
    isUploadPopupBackground: false
}

export function setComment(comment: string) {
    return dispatch => {
        dispatch({type: SET_COMMENT, payload: comment});
    }
}

export function setCommentPopupOpen(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_COMMENT_POPUP_OPEN, payload: flag});
    }
}

export function clearUploads() {
    return dispatch => {
        dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
    }
}

export function setIsSnackbarOpen(isOpen: boolean) {
    return dispatch => {
        dispatch({type: SET_IS_SNACKBAR_OPEN, payload: isOpen});
    }
}

export function setFirstDocumentLoading(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_FIRST_DOCUMENT_LOADING, payload: flag});
    }
}

export function setSecondDocumentLoading(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_SECOND_DOCUMENT_LOADING, payload: flag});
    }
}

export function setShowPreviewPages(value: boolean) {
    return dispatch => {
        dispatch({type: SHOW_PREVIEW_PAGES, payload: value});
    }
}

export function setSelectedIds(ids: string[]) {
    return dispatch => {
        dispatch({type: SET_SELECTED_FILE_IDS, payload: ids});
    }
}

export function uploadFiles(uploads: IFileFromInput[], pairDoc: PairDocs): Thunk {
    return async (dispatch, getState) => {
        const addFilesIntoPackage = async (uploadPackage: IPair) => {
            if (pairDoc === PairDocs.First) {
                dispatch(setFirstDocumentLoading(true))
            } else {
                dispatch(setSecondDocumentLoading(true))
            }

            await getUploadApiObj()
                .addFileToPackage({
                    pairId: uploadPackage.id,
                    doc: pairDoc,
                    files: uploads.map(elem => ({
                        mimeType: elem.mimeType,
                        base64Content: elem.fileUrl.split('base64,')[1],
                        name: elem.fileName,
                    }))
                })
                .then(res => res.json())
                .then(async res => {
                    dispatch(setUploads(uploads, true, pairDoc))
                })
                .catch((err) => {
                    if (pairDoc === PairDocs.First) {
                        dispatch(setFirstDocumentLoading(false))
                    } else {
                        dispatch(setSecondDocumentLoading(false))
                    }
                    try {
                        const error = JSON.parse(err);
                        console.log("error", error, error.text)
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                        dispatch({
                            type: UPDATE_TECH_REPORT_TEXT,
                            payload: "",
                        })
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: [err.message ? err.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }
                })
                .finally(() => {
                    if (pairDoc === PairDocs.First) {
                        dispatch(setFirstDocumentLoading(false))
                    } else {
                        dispatch(setSecondDocumentLoading(false))
                    }
                });
            if (pairDoc === PairDocs.First) {
                dispatch(setFirstDocumentLoading(false))
            } else {
                dispatch(setSecondDocumentLoading(false))
            }
        }
        const detailedPackageFromState = getState().uploads.pair;
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackage = detailedPackageFromState
            ? detailedPackageFromState
            : detailedPackageStorage
                ? detailedPackageStorage
                : undefined;
        if (detailedPackage) {
            await addFilesIntoPackage(detailedPackage);
        } else {
            await getUploadApiObj()
                .createPair()
                .then(res => res.json())
                .then(async res => {
                    const uploadPackage: IPair = res;
                    // setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, {package: uploadPackage}, 8);
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: uploadPackage});
                    await addFilesIntoPackage(uploadPackage);
                })
                .catch((err) => {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            isDefaultError: true,
                            data: ["Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }

                    console.log('createPair', err)
                })
                .finally(() => {
                    if (pairDoc === PairDocs.First) {
                        dispatch(setFirstDocumentLoading(false))
                    } else {
                        dispatch(setSecondDocumentLoading(false))
                    }
                })
        }
    }
}

export function uploadAndRecognizePair({firstDocument, secondDocument}: PairUploading): Thunk {
    return async (dispatch, getState) => {
        dispatch(addMockPair(firstDocument[0]?.fileName ?? '', secondDocument[0]?.fileName ?? ''))
        const addFilesIntoPackage = async (uploadPackage: IPair) =>  Promise.allSettled([ getUploadApiObj()
            .addFileToPackage({
                pairId: uploadPackage.id,
                doc: PairDocs.First,
                files: firstDocument.map(elem => ({
                    mimeType: elem.mimeType,
                    base64Content: elem.fileUrl.split('base64,')[1],
                    name: elem.fileName,
                }))
            })
            .then(res => res.json())
            .then(async res => {
                dispatch(setUploads(firstDocument, true, PairDocs.First))
            })
            .catch((err) => {
                try {
                    const error = JSON.parse(err);
                    console.log("error", error, error.text)
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        isDefaultError: true,
                        actionVisible: true,
                        data: [error.message ? error.message : "Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                } catch (e) {
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        isDefaultError: true,
                        data: [err.message ? err.message : "Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                }
            }), getUploadApiObj()
            .addFileToPackage({
                pairId: uploadPackage.id,
                doc: PairDocs.Second,
                files: secondDocument.map(elem => ({
                    mimeType: elem.mimeType,
                    base64Content: elem.fileUrl.split('base64,')[1],
                    name: elem.fileName,
                }))
            })
            .then(res => res.json())
            .then(async res => {
                dispatch(setUploads(secondDocument, true, PairDocs.Second))
            })
            .catch((err) => {
                try {
                    const error = JSON.parse(err);
                    console.log("error", error, error.text)
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        isDefaultError: true,
                        actionVisible: true,
                        data: [error.message ? error.message : "Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                } catch (e) {
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        isDefaultError: true,
                        actionVisible: true,
                        data: [err.message ? err.message : "Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                }
            })])
        const detailedPackageFromState = getState().uploads.pair;
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackage = detailedPackageFromState
            ? detailedPackageFromState
            : detailedPackageStorage
                ? detailedPackageStorage
                : undefined;
        if (detailedPackage) {
            await addFilesIntoPackage(detailedPackage).then(() => {
                dispatch(sendPairToRecognition(true));
            })
                .catch((err) => {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            isDefaultError: true,
                            data: ["Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }

                    console.log('createPair', err)
                });
        } else {
            await getUploadApiObj()
                .createPair()
                .then(res => res.json())
                .then(async res => {
                    const uploadPackage: IPair = res;
                    // setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, {package: uploadPackage}, 8);
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: uploadPackage});
                    await addFilesIntoPackage(uploadPackage).then(() => {
                        dispatch(sendPairToRecognition(true))
                    });
                })
                .catch((err) => {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            isDefaultError: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: ["Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }

                    console.log('createPair', err)
                })
        }
    }
}

export function getUploads(): Thunk {
    return async (dispatch, getState) => {
        const detailedPackageFromState = getState().uploads.pair;
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackage: IPair = detailedPackageStorage && !detailedPackageFromState ? detailedPackageStorage : undefined
        let images = [];
        if (detailedPackage) {
            dispatch(spinnerPlusStatus())
            await getUploadApiObj()
                .getImagesByPackageId(detailedPackage.id)
                .then(res => res.json())
                .then(async res => {
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: detailedPackage});
                    const addedFile: IImagesByPackageId = res;
                    for (let i = 0; i < addedFile.imgId.length; i++) {
                        const imageId = addedFile.imgId[i];
                        await getUploadApiObj()
                            .getImageById(imageId)
                            .then(response => response.json())
                            .then(response => {
                                const imageBase64: IConvertedImage = response;
                                images.push(imageBase64.rawBase64);
                                dispatch(
                                    setUploads([
                                        {
                                            mimeType: 'data:image/jpg;base64',
                                            fileUrl: `data:image/jpg;base64,${imageBase64.rawBase64}`,
                                            fileName: '',
                                            id: `${new Date().getTime()}${i}`,
                                            imageId: imageId,
                                        }
                                    ], true, PairDocs.First)
                                )
                            })
                            .catch((err) => {
                                console.log("err", err)
                            })
                            .finally(() => {
                                dispatch(spinnerMinusStatus())
                            })

                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                })
            dispatch(spinnerMinusStatus())
        }
    }
}

export function setUploads(uploads: IFileFromInput[], usePrevUploads: boolean = false, pairDoc?: PairDocs): Thunk {
    return (dispatch, getState) => {
        const prevUploads = pairDoc === PairDocs.First ? selectFirstDocumentFiles(getState()) : selectSecondDocumentFiles(getState());
        if (uploads.length === 0) {
            dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined});
        }
        if (pairDoc === PairDocs.First) {
            dispatch({
                type: UPDATE_UPLOAD_FIRST_DOCUMENT,
                payload: usePrevUploads ? [...prevUploads, ...uploads] : [...uploads]
            });
        } else {
            dispatch({
                type: UPDATE_UPLOAD_SECOND_DOCUMENT,
                payload: usePrevUploads ? [...prevUploads, ...uploads] : [...uploads]
            });
        }
    }
}

export function deleteUploads(newUploads: IFileFromInput[]): Thunk {
    return async (dispatch, getState) => {
        const packageDetailed: IPair = selectUploadPackage(getState());
        dispatch(spinnerPlusStatus())
        if (newUploads.length > 0) {
            await getUploadApiObj()
                .sortPackage(packageDetailed.id)
                .then(() => {
                    dispatch({type: UPDATE_UPLOAD_FILES, payload: newUploads});
                })
                .catch((err) => {
                    console.log('sort package', err)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                })
        } else {
            dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
            dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
            dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
            setLocaleStorageItem(PAIR_STORAGE_NAME, undefined, 10);
        }
        dispatch(spinnerMinusStatus())
    }
}

export function sendTechnicalReport(props: ITechReport): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus())
        await getMonitoringApiObj()
            .sendTechnicalReport({...props, message: selectTechReportText(getState())})
            .then(() => {
                dispatch(popupPush({
                    data: ["Информация отправлена в техподдержку, мы изучим проблему и ответим с 9:00 до 18:00 по московскому времени. Обычно мы отвечаем в течение одного рабочего дня."],
                    cancelTitle: "Хорошо",
                    cancelVisible: true,
                }))
                dispatch({
                    type: UPDATE_TECH_REPORT_TEXT,
                    payload: "",
                })
            })
            .catch((err) => {
                try {
                    const error = JSON.parse(err);
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        isDefaultError: true,
                        data: [error.message ? error.message : "Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                } catch (e) {
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: ["Произошла ошибка"],
                        isDefaultError: true,
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                }

                console.log('sendTechnicalReport', err)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function sendPairToRecognition(background: boolean = false): Thunk {
    return async (dispatch, getState) => {
        !background && dispatch(spinnerPlusStatus())
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackageFromState = selectUploadPackage(getState());
        const detailedPackage: IPair = detailedPackageFromState
            ? detailedPackageFromState
            : detailedPackageStorage
                ? detailedPackageStorage
                : undefined;
        if (detailedPackage) {
            await getUploadApiObj()
                .sendPairToRecognition(detailedPackage.id)
                .then((res) => {
                    !background && setTimeout(() => {
                        dispatch(setIsSnackbarOpen(true));
                    }, 250);
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
                    dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
                    dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
                    setLocaleStorageItem(PAIR_STORAGE_NAME, undefined, 10);
                    !background && dispatch(push(`${ROUTES.Docs}/${detailedPackage.key}`))
                    dispatch(onClearUploads());
                    dispatch({type: SET_UPLOAD_POPUP_OPEN, payload: false});
                })
                .catch((error) => {
                    console.log("sendPackageToRecognition", error)
                })
        }

        dispatch(spinnerMinusStatus())
    }
}

export function setDetailedUpload(uploadFile: IFileFromInput | undefined) {
    return dispatch => {
        dispatch({type: SET_DETAILED_UPLOAD, payload: uploadFile});
    }
}

export function onClearUploads() {
    return dispatch => {
        setLocaleStorageItem(PAIR_STORAGE_NAME, undefined, 30)
        dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
        dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
        dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
        dispatch({type: UPDATE_UPLOAD_FIRST_DOCUMENT, payload: []});
        dispatch({type: UPDATE_UPLOAD_SECOND_DOCUMENT, payload: []});
    }
}

export function setUploadPageCount(count: number) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_PAGE_COUNT, payload: count});
    }
}

export function updateTechReportText(value: string) {
    return dispatch => {
        dispatch({type: UPDATE_TECH_REPORT_TEXT, payload: value});
    }
}

export function setDetailedFilePages(page: IDetailedDocumentPage | undefined) {
    return dispatch => {
        dispatch({type: SET_DETAILED_FILE_PAGE, payload: page});
    }
}

export function setUploadIsLoading(isLoading: boolean) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_IS_LOADING, payload: isLoading});
    }
}

export function setUploadPopupOpen(isOpen: boolean) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_POPUP_OPEN, payload: isOpen});
    }
}

export function setUploadPopupBackground(isBackground: boolean) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_POPUP_BACKGROUND, payload: isBackground});
    }
}

export function uploadsReducer(
    state: IUploadState = initialState,
    action: UploadActions
): IUploadState {
    switch (action.type) {
        case UPDATE_UPLOAD_FILES:
            return {
                ...state,
                files: action.payload,
            };
        case SET_DETAILED_UPLOAD:
            return {
                ...state,
                detailedFile: action.payload,
            };
        case SET_UPLOAD_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_UPLOAD_PAGE_COUNT:
            return {
                ...state,
                pageCount: action.payload,
            };
        case SET_SELECTED_FILE_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            };
        case SET_DETAILED_FILE_PAGE:
            return {
                ...state,
                page: action.payload,
            };
        case SHOW_PREVIEW_PAGES:
            return {
                ...state,
                showPreviewPages: action.payload,
            };
        case SET_UPLOAD_POPUP_OPEN:
            return {
                ...state,
                isUploadPopupOpen: action.payload,
            };
        case UPDATE_UPLOAD_PACKAGE:
            return {
                ...state,
                pair: action.payload,
            };
        case UPDATE_TECH_REPORT_TEXT:
            return {
                ...state,
                reportText: action.payload,
            };
        case SET_SECOND_DOCUMENT_LOADING:
            return {
                ...state,
                secondDocumentLoading: action.payload,
            };
        case SET_FIRST_DOCUMENT_LOADING:
            return {
                ...state,
                firstDocumentLoading: action.payload,
            };
        case UPDATE_UPLOAD_FIRST_DOCUMENT:
            return {
                ...state,
                firstDocumentFiles: action.payload,
            };
        case UPDATE_UPLOAD_SECOND_DOCUMENT:
            return {
                ...state,
                secondDocumentFiles: action.payload,
            };
        case SET_COMMENT:
            return {...state, comment: action.payload};
        case SET_IS_SNACKBAR_OPEN:
            return {...state, isSnackbarOpen: action.payload};
        case SET_COMMENT_POPUP_OPEN:
            return {...state, commentPopupOpen: action.payload};
        case SET_UPLOAD_POPUP_BACKGROUND:
            return {...state, isUploadPopupBackground: action.payload};
        default:
            return state;
    }
}

