import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#F57B20',
        }
        ,secondary: {
            main: '#F57B20',
        }
    }
});
