import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import DifferenceListSidebar, {
    IDifferenceListSidebarDispatchProps,
    IDifferenceListSidebarStateProps
} from "./DifferenceListSidebar";
import {
    deleteUnrecognizedPackagesImages,
    setDetailedDiff,
    setDifferenceData,
    setDifferenceStatus,
    setIsChangingDetailedDiff,
    setIsTrackDisabled,
    setLeftScale,
    setMenuOpen,
    setRightScale,
} from "../../ducks/montitoring";
import {popupPush} from "../../ducks/popup";
import {routeTo} from "../../ducks/baseCRUD";
import {sendTechnicalReport, setComment, updateTechReportText} from "../../ducks/upload";
import {
    selectDetailedDiff,
    selectDifferenceBarPosition,
    selectDifferenceData,
    selectDocFilterList,
    selectFirstDocumentPosition,
    selectIsChangingDetailedDiff,
    selectIsChangingView,
    selectMenuOpen
} from "../../selectors/monitoring";
import {selectUrlId} from "../../selectors/other";
import {selectComment} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<IDifferenceListSidebarStateProps, {}, State> = state => {
    const detailedDiff = selectDetailedDiff(state);
    const panelPosition = selectDifferenceBarPosition(state);
    const docPosition = selectFirstDocumentPosition(state);
    const menuOpen = selectMenuOpen(state);
    const urlId = selectUrlId(state);
    const comment = selectComment(state);


    return {
        barWidth: menuOpen ? 300 : 59,
        urlId,
        docFilterList: selectDocFilterList(state),
        isChangingView: selectIsChangingView(state),
        isChangingDetAiledDiff: selectIsChangingDetailedDiff(state),
        differenceData: selectDifferenceData(state),
        detailedDiff,
        menuOpen: menuOpen,
        panelPosition,
        docPosition,
        comment
    }
};

const dispatchProps: MapDispatchToProps<IDifferenceListSidebarDispatchProps, {}> = {
    setDetailedDiff,
    popupPush,
    routeTo: (route, id?) => {
        return routeTo(route, id)
    },
    setDifferenceStatus,
    setLeftScale,
    setRightScale,
    setIsTrackDisabled,
    deleteUnrecognizedPackagesImages,
    sendTechnicalReport,
    updateTechReportText,
    setDifferenceData,
    setIsChangingDetailedDiff,
    setMenuOpen,
    setComment,

};

export const DifferenceListSidebarConnected = connect(
    mapStateToProps,
    dispatchProps,
)(DifferenceListSidebar);
