import {getEndpoint} from "./endpoints";
import {IImagesByPackageId} from "./uploadApi";
import {DeleteRowProps, GET_DOCUMENTS_STATE, IDetailedPair, IExchangeParams} from "../ducks/montitoring";
import AbortAddon from "wretch/addons/abort";
import {customControllerUtils} from "../abortController/abortController";

export interface IMonitoringFilter {
    extendData: boolean;
    filter: {
        compareDateTo?: string;
        compareDateFrom?: string;
        key?: string;
        status: any[];
        pair?: number;
        offset?: number;
        limit?: number;
    }

}

export interface MonitoringItemImage {
    place: number;
    name: string;
    mimeType: string;
}

export interface IMonitoringItem {
    id: number;
    key: string;
    images: MonitoringItemImage[];
    compareDate: string;
    comment: string;
    createdAt: string;
    updatedAt: string;
    status: {
        id: number
    };
    discrepanciesData: IDiscrepancyData[];
    state: GET_DOCUMENTS_STATE;
    discrepancyRatio: number;
}

export type CreateHashProps = {
    pairId: number;
    lifeTime: number;
}

export type CreateHashRes = {
    hash: string,
    expiredAt: string,
    createdAt: string,
    updatedAt: string
}

export interface IImageId {
    id: number;
}

export interface IImagesIds {
    firstDocumentImages: IImageId[];
    secondDocumentImages: IImageId[];
}

export const defaultFilter: IMonitoringFilter = {
    extendData: false,
    filter: {
        status: [],
    }
}

export interface IDiscrepancyStatus {
    important: boolean;
}

export interface IDiscrepancyData {
    type: string;
    status: IDiscrepancyStatus[];
    details: IDifference[];
}

export interface IDifference {
    rowId: string;
    difference: number;
    documentPageIndex: number;
    documentId: string;
    location: IDifferenceLocation;
    discrepancyIndex?: number;
}

export interface IDifferencePart{
    type: DifferenceType;
    indexFromApi: number;
    imageId: number,
    pageIndex: number,
    coordinates: IDifferenceLocation
    discrepancyElements: string[];
    isImportant: boolean;
}

export enum DifferenceType {
    Added = "Added",
    Changed = "Changed",
    Deleted = "Deleted",
    AddedPages = "AddedPages",
    DeletedPages = "DeletedPages",
    ChangedPages = "ChangedPages",
}

export interface IDifferenceData {
    indexFromApi: number;
    important: boolean;
    type: DifferenceType;
    addedText?: string;
    deletedText?: string;
    changedTextBefore?: string;
    changedTextAfter?: string;
    locationsOnFirstDocument: IDifferencePart[];
    locationsOnSecondDocument:IDifferencePart[];
    discrepancyElements: string[];
    punctuationMarksCount: number;
    comment: string;
}

export interface IDifferenceLocation {
    left: number,
    top: number,
    right: number,
    bottom: number,
    width: number,
    height: number
}

export interface IDocumentImageId {
    finalImgBase64: string,
}

export interface IDocumentImage {
    imageId: number;
    loading: boolean;
    fileUrl: string,
}

export interface IUpdateDocument {
    status: number;
    comment: string,
    jsonContent?: string;
}

export interface IUpdateDocumentList {
    status: number;
    comment: string,
    docId: string,
    discrepancy: IDiscrepancyData[],
    jsonContent?: string;
}

export interface INotRecognizedPackage {
    docId: any,
    loadDate: any,
    packageId: number,
    user: string,
    created: boolean,
    docflowDocumentData: any,
    jsonContent: {
        id: any,
        docType: any,
        pages: {
            id: 237004
        },
        contractorStamp: any,
        customerStamp: any,
        registrationStamp: any,
        signatures: any,
        codes: any,
        fields: any,
        saveData: any
    }
}

export interface ITechReport {
    subject: string;
    message: string;
    packageId: number[];
}

export type GetPdf = {
    pairId: number;
    onlyImportant: boolean;
    discrepancies: IDifferenceData[];
}

export type GetPublicPdf = Omit<GetPdf, 'pairId'> & {
    hash: string;
};

class MonitoringApi {
    async getList(props: IMonitoringFilter) {
        return await getEndpoint("/pairs/list",)
            .post({...props})
            .res();
    }

    async getDetailedDocumentByDocId(docId: string) {
        return await getEndpoint(`/doc/${docId}`,)
            .get()
            .res();
    }

    async getDocuments(id: number) {
        return await getEndpoint(`/pairs/${id}`,)
            .addon(AbortAddon())
            .signal(customControllerUtils.controller)
            .get()
            .res();
    }

    async getPublicDocuments(hash: string) {
        return await getEndpoint(`/pairs/public/${hash}`,)
            .get()
            .res();
    }

    async createHash(props: CreateHashProps) {
        return await getEndpoint(`/pairs/short`,)
            .post(props)
            .res();
    }


    async updateDocuments(id: number, props: IDetailedPair) {
        return await getEndpoint(`/pairs/${id}`,)
            .put({...props})
            .res();
    }

    async getImage(imageId: number) {
        return await getEndpoint(`/pairs/images/${imageId}`,)
            .addon(AbortAddon())
            .signal(customControllerUtils.controller)
            .get()
            .res();
    }
    async getPublicImage(id: number, hash: string) {
        return await getEndpoint(`/pairs/public/${hash}/images/${id}`)
            .addon(AbortAddon())
            .signal(customControllerUtils.controller)
            .get()
            .res();
    }

    async getImages(imageId: number) {
        return await getEndpoint(`/pairs/${imageId}/images/list`,)
            .get()
            .res();
    }
    async getPublicImages(hash: string) {
        return await getEndpoint(`/pairs/public/${hash}/images/list`,)
            .get()
            .res();
    }

    async getDocumentImage(docId: string | number) {
        return await getEndpoint(`/doc/img/${docId}`,)
            .get()
            .res();
    }

    async getDocIdByKey(key: string) {
        return await getEndpoint(`/pairs/key/${key}`,)
            .get()
            .res();
    }

    async sendTechnicalReport(props: ITechReport) {
        return await getEndpoint(`/notification`,)
            .post(props)
            .res();
    }

    async updateDocumentStatus(props: IUpdateDocumentList[]) {
        return await getEndpoint(`/doc/list`,)
            .post(props)
            .res();
    }

    async getPdf({pairId, onlyImportant, discrepancies}:GetPdf) {
        const props = {
            onlyImportant,
            discrepancies,
        };
        return await getEndpoint(`/pairs/${pairId}/pdf`,)
            .post(props)
            .res();
    }

    async getPublicPdf({onlyImportant, discrepancies, hash}:GetPublicPdf) {
        const props = {
            onlyImportant,
            discrepancies,
        };
        return await getEndpoint(`/pairs/public/${hash}/pdf`,)
            .post(props)
            .res();
    }

    async getUnrecognizedPackage(packageId: number) {
        return await getEndpoint(`/package/${packageId}/unrecognized`,)
            .get()
            .res();
    }

    async deleteUnrecognizedPackage(packageId: number, imagesId: IImagesByPackageId) {
        return await getEndpoint(`/packageid/${packageId}/unrecognized/image`,)
            .json(imagesId)
            .delete()
            .res();
    }


    async getExtendParams() {
        return await getEndpoint(`/paramsExtend/pair`,)
            .get()
            .res();
    }

    async setExtendParams(params: IExchangeParams) {
        return await getEndpoint(`/paramsExtend/pair`,)
            .post(params)
            .res();
    }
    async deleteRows(params: DeleteRowProps) {
        return await getEndpoint(`/pairs/delete`,)
            .post(params)
            .res();
    }

}

export function getMonitoringApiObj() {
    return new MonitoringApi();
}
