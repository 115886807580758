import React, {CSSProperties} from 'react';
import MaterialTable, {Column, MTableBodyRow} from "material-table";
import {Paper, ThemeProvider} from "@mui/material";
import {materialTableLocalizationRu} from "./materialTableLocalization";
import {TablePagination} from '@material-ui/core';
import {tableIcons} from "./tableIcons";
import styles from "./Table.module.scss"
import {IRecognitionActTable} from "../../pages/MonitoringPage/MonitoringPage";
import {theme} from "../../theme/theme";

export interface ITableProps {
    pageSize: number;
    initialPage?: number;
    columns: Column<Object>[];
    onChangeListPage?: (pageNumber: number) => void;
    data: Object[];
    onRowClick?: (event: any, rowData: any) => void;
    onContextMenu?: (event: any, rowData: any) => void;
    selection?: boolean;
    onSelectRow?: (rows: IRecognitionActTable[], rowData?: IRecognitionActTable) => void;
    selectedIds?: number[];
}

export function Table(props: ITableProps) {
    const {columns, initialPage, pageSize, data, onRowClick, selection, onSelectRow, selectedIds, onContextMenu} = props;
    const prepareColumns = columns.map((col, index) => ({
        ...col,
        cellStyle: col.field !== "status"
            ? {} as CSSProperties
            : {width: '5%',} as CSSProperties,
        headerStyle: col.field !== "status"
            ? {
                zIndex: 0,
                backgroundColor: '#FFF',
                fontFamily: "'Lato', sans-serif",
                fontSize: '18px',
                fontWeight: '400 !important',
                lineHeight: '18px',
                letterSpacing: '0em',
                textAlign: 'left',
                width: "auto !important",
            } as CSSProperties
            : {
                textAlign: 'left',
                alignItems: 'left !important',
                zIndex: 0,
                backgroundColor: '#FFF',
                fontFamily: "'Lato', sans-serif",
                fontSize: '18px',
                fontWeight: '400 !important',
                lineHeight: '18px',
                letterSpacing: '0em',

} as CSSProperties
    }));

    const changePage = e => {
        if (props.onChangeListPage) {
            props.onChangeListPage(e)
        }
    }

    return <div className={styles.tableContainer}>
        <ThemeProvider theme={theme}>
        <MaterialTable
            components={{
                Container: props => <Paper {...props} elevation={0}/>,
                Row: props =>(<MTableBodyRow
                    {...props}
                    onContextMenu={(e) => onContextMenu(e, props.data)}
                />),
                Pagination: props1 => <TablePagination {...props1}/>,
            }}
            columns={prepareColumns}
            data={data}
            localization={materialTableLocalizationRu}
            icons={tableIcons}
            onRowClick={onRowClick}
            options={{
                rowStyle: {
                    zIndex: '0 !important',
                    fontFamily: "'Lato', sans-serif",
                    fontSize: '16px',
                    fontWeight: '300 !important',
                    lineHeight: '20px',
                    letterSpacing: '0em',
                },
                initialPage: initialPage,
                pageSizeOptions: [],
                selection,
                pageSize,
                actionsColumnIndex: 7,
                sorting: true,
                thirdSortClick: true,
                emptyRowsWhenPaging: true,
                draggable: false,
                search: false,
                searchFieldAlignment: "left",
                showTitle: false,
                toolbar: false,
                selectionProps: (rowData: IRecognitionActTable) => ({
                    checked: selectedIds?.includes(rowData?.id),
                }),
                headerSelectionProps: {
                    checked: (selectedIds.length === data.length) && data.length > 0
                }
            }}
            onSelectionChange={(data: IRecognitionActTable[], rowData: IRecognitionActTable) => {
                onSelectRow(data,rowData);
            }}
            onChangePage={changePage}
            actions={[
                {
                    tooltip: 'Удалить',
                    icon: 'delete',
                    onClick: (evt, data) => alert('You want to delete ' + selectedIds.length + ' rows')
                }
            ]}
        />
        </ThemeProvider>
    </div>
}
