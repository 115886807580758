import styles from './MonitoringPage.module.scss';
import React, {useEffect, useState} from "react";
import {ITableProps, Table} from "../../components/table/Table";
import PageWrapper from "../../components/pageWrapper/PageWeapper";
import {ROUTES} from "../../app/Routes";
import {getLocaleStorageItem, tableCustomDateSort, truncate} from "../../helper/other";
import "moment/locale/ru";
import {DeleteRowProps, MonitoringStatuses, setSortType, SortDirections} from "../../ducks/montitoring";
import {Icon, ListItemIcon, Menu, MenuItem,} from "@mui/material";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'rsuite/styles/index.less';
import "./calendarCSS.css";
import {IMonitoringFilter} from "../../api/monitoringApi";
import {getStoreUtils} from "../../store/utils";
import {Column} from "material-table";
import {getSortType, setStorageSortType} from "../../selectors/monitoring";
import {setUploadPopupBackground} from "../../ducks/upload";
import processing from "./processing.svg";
import twoDocOkStatus from "../../twoDocOkStatus.svg";
import twoDocsErrorStatus from "../../twoDocsErrorStatus.svg";
import processFinishOkStatus from "../../processFinishokStatus.svg";
import {ThemeProvider, withStyles} from "@mui/styles";
import {theme} from "../../theme/theme";
import {PopupElement} from "../../ducks/popup";

export interface IFilterOption {
    value: number;
    label: string;
    icon?: string;
    isHidden?: boolean;
}
export interface IMonitoringPageStateProps {
    list: IRecognitionActTable[];
    isUploadPopupOpen: boolean;
    options: string[];
    sort: string;
    listPage: number;
    selectedRowIds: number[];
}

export interface IMonitoringPageDispatchProps {
    getRecognitionActs: (filter?: IMonitoringFilter, isSearch?: boolean) => void;
    routeTo: (route: string | null, id?: number) => void;
    setSortType: (sort: SortDirections | undefined) => void;
    setSearchOptions: (options: string[]) => void;
    setListPage: (pageNumber: number) => void;
    setSelectedRowIds: (ids: number[]) => void;
    clearMonitoringList: () => void;
    popupPush: (popup: PopupElement) => void;
    deleteTableRows: (props: DeleteRowProps) => void;
}

export interface IMonitoringPageState {
    dateFrom: string;
    dateTo: string;
    statuses: IFilterOption[];
    search: string;
    status: number;
    timeout: any;
}

export type MonitoringPageProps = IMonitoringPageStateProps & IMonitoringPageDispatchProps;

let interval = undefined;

export interface IRecognitionActTable {
    id: number;
    key: string;
    firstDocument: string;
    secondDocument: string;
    compareDate: string;
    comment: string;
    upload_date: string;
    status: string;
    statusId: number;
    discrepancyRatio: number;
}

export const defaultTableColumns: Column<Object>[] = [
    {
        title: 'СТАТУС',
        field: 'status',
        sorting: true,
        render: rowData => {
            const {status, statusId} = rowData as IRecognitionActTable;
            let statusIcon = processing;
            let statusLabel = status;
            switch (statusId) {
                case MonitoringStatuses.COMPARED:
                case MonitoringStatuses.NEW:
                case MonitoringStatuses.SENDING:
                    statusLabel = 'Обрабатывается';
                    break;
                case MonitoringStatuses.HAS_DIFFERENCES:
                    statusIcon = twoDocsErrorStatus;
                    break;
                case MonitoringStatuses.HAS_NO_DIFFERENCES:
                    statusIcon = twoDocOkStatus;
                    break;
                case MonitoringStatuses.FINISHED:
                    statusIcon = processFinishOkStatus;
                    break;
            }
            return <div
                onClick={() => {
                }}
                className={styles.iconRow} title={statusLabel ?? ''}>
                <ListItemIcon sx={{width: "35px", height: "35px"}}>
                    <Icon sx={{width: "35px", height: "35px"}}>
                        <img alt={""} src={statusIcon} width={35}
                             height={35}/>
                    </Icon>
                </ListItemIcon>
            </div>;
        }
    },
    {
        title: 'ПРОЦЕНТ СООТВЕТСТВИЯ',
        field: 'discrepancyRatio',
        sorting: true,
        render: rowData => {
            const {discrepancyRatio} = rowData as IRecognitionActTable;
            if(discrepancyRatio == null) {
                return <></>;
            }

            const ratio = 1 - discrepancyRatio;

            if(ratio <= 0.3) {
                return <div
                    className={styles.negativeStatus}
                >
                    {discrepancyRatio}
                </div>
            }
            if(ratio >= 0.3 && ratio < 0.7 ) {
                return <div
                    className={styles.warningStatus}
                >
                    {discrepancyRatio}
                </div>
            }
            return <div
                className={styles.positiveStatus}
            >
                {`${ratio * 100}%`}
            </div>;
        }
    },
    {
        title: 'ДАТА ЗАГРУЗКИ',
        field: 'upload_date',
        sorting: true,

    },
    {
        title: 'ЭТАЛОН', sorting: false, field: 'firstDocument', render: rowData => {
            const {firstDocument} = rowData as IRecognitionActTable;
            return <div
                title={firstDocument}
            >
                {truncate(firstDocument, 50)}
            </div>;
        }
    },
    {
        title: 'ИЗМЕНЕННЫЙ ДОКУМЕНТ', sorting: false, field: 'secondDocument',
        render: rowData => {
            const {secondDocument} = rowData as IRecognitionActTable;
            return <div
                title={secondDocument}
            >
                {truncate(secondDocument, 50)}
            </div>;
        }
    },
    {
        title: 'КОММЕНТАРИЙ', sorting: false, field: 'comment',
        render: rowData => {
            const {comment} = rowData as IRecognitionActTable;
            return <div
                title={comment}
            >
                {truncate(comment, 50)}
            </div>;
        }
    },
];

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #000000',
        boxShadow: "none"
    },
})(Menu);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        fontWeight: 500,
    },
}))(MenuItem);

export function MonitoringPage(props: MonitoringPageProps) {
    let stateFromStorage: IMonitoringPageState = undefined;
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        rowId: number;
    } | null>(null);
    const onSelectRow = (rows: IRecognitionActTable[], rowData?: IRecognitionActTable) => {
        const selectedIds: number[] = structuredClone(props.selectedRowIds);
        if(rowData){
            const findIndex = selectedIds.findIndex((el) => rowData?.id === el);
            if(findIndex >= 0) {
                selectedIds.splice(findIndex,1);
            } else {
                selectedIds.push(rowData?.id);
            }
            props.setSelectedRowIds(selectedIds);
        } else {
            if(rows.length === props.list.length) {
                props.setSelectedRowIds(rows.map(el => el.id));
            } else {
                props.setSelectedRowIds([]);
            }
        }
    }

    try {
        //@ts-ignore
        stateFromStorage = JSON.parse(getLocaleStorageItem("stateFromStorage"));
    } catch (e) {
        console.log("e", e, stateFromStorage)
    }
    const [sort, setSort] = useState('upload_date')
    const data = sort === 'upload_date' ? props.list.sort((a, b) => tableCustomDateSort(
        b.upload_date,
        a.upload_date,
        "DD.MM.YYYY HH:mm:ss",
        props.sort ?? SortDirections.DESC
    )) : props.list.sort((a, b) => props.sort === SortDirections.ASC ? +a.statusId - +b.statusId : +b.statusId - +a.statusId)
    const tableProps: ITableProps = {
        pageSize: 18,
        selection: true,
        onChangeListPage: (pageNumber => {
            props.setListPage(pageNumber)
        }),
        initialPage: props.listPage,
        data: [...data],
        //@ts-ignore
        columns: defaultTableColumns.map(c => {
            if (c.field === 'status' || c.field === 'upload_date') {
                const c1 = {
                    ...c, customSort: (a, b) => {
                        const item = getSortType();
                        if (interval) {
                            clearTimeout(interval)
                        }
                        interval = setTimeout(() => {
                            if (item === SortDirections.ASC) {
                                setStorageSortType(SortDirections.DESC)
                                getStoreUtils().dispatch(setSortType(SortDirections.DESC));
                            } else if (item === SortDirections.DESC || !item) {
                                setStorageSortType(SortDirections.ASC)
                                getStoreUtils().dispatch(setSortType(SortDirections.ASC));
                            }
                        }, 0)
                        setSort(c1.field)
                        return 1
                    }
                }
                return c1
            } else return c
        })
        ,
        onRowClick: (event, rowData) => {
            const { key, statusId} = rowData as IRecognitionActTable;
            if(statusId !== MonitoringStatuses.UPLOADING) {
                props.routeTo(`${ROUTES.Docs}/${key}`);
            }
        },
        onContextMenu: (event, rowData) => {
            event.preventDefault();
            event.preventDefault();
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 5,
                        mouseY: event.clientY + 15,
                        rowId: rowData.id,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                      // Other native context menus might behave different.
                      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );

        },
        selectedIds: props.selectedRowIds,
        onSelectRow,
    }

    useEffect(() => {
        return () => {
            props.clearMonitoringList();
        }
    }, [])

    useEffect(() => {
        if (!props.isUploadPopupOpen) {
            props.getRecognitionActs({
                extendData: false,
                filter: {
                    status: [],
                }
            });
        }
    }, []);

    const onShowDropzone = () => {
        setTimeout(() => {
            getStoreUtils().dispatch(setUploadPopupBackground(true))
        }, 0);
    }

    const onDeleteTableRows = () => {
        setContextMenu(null);
        props.popupPush({
            actionTitle: "Да",
            cancelTitle: 'Отмена',
            actionVisible: true,
            data: [`Вы действительно хотите удалить выбранные документы?`],
            actionHandler: () => {
                if(contextMenu?.rowId)
                props.deleteTableRows({
                    pairIds: props.selectedRowIds.includes(contextMenu.rowId)
                        ? props.selectedRowIds
                        : [contextMenu.rowId, ...props.selectedRowIds]
                })
            },
            cancelVisible: true,
        });
    }

    return <PageWrapper>
        <div
            onDragEnter={onShowDropzone}
            className={styles.table}
        >
            <StyledMenu
                id="simple-menu"
                open={contextMenu !== null}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                onClose={(e) => {
                    setContextMenu(null);
                }}>
                <StyledMenuItem
                    onClick={onDeleteTableRows}
                >
                    {(props.selectedRowIds.length > 1 || (props.selectedRowIds.length === 1 && !props.selectedRowIds.includes(contextMenu?.rowId))) ? 'Удалить выбранное' : 'Удалить'}
                </StyledMenuItem>
            </StyledMenu>
            <ThemeProvider theme={theme}>
                <Table {...tableProps}/>
            </ThemeProvider>
        </div>
    </PageWrapper>
}
