import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {DifferencePage, IDifferencePageDispatchProps, IDifferencePageStateProps} from "./DifferencePage";
import {
    selectDetailedDiff,
    selectDetailedDocIdLeft,
    selectDetailedPair,
    selectDifferenceBarPosition,
    selectDifferenceData,
    selectDocFilterList,
    selectFirstDocumentPosition,
    selectIsChangingDetailedDiff,
    selectIsChangingView,
    selectIsPageLoading,
    selectLeftImages,
    selectLeftScale,
    selectMagnifierAdded,
    selectMenuOpen,
    selectRightImages,
    selectRightScale,
    selectScrollSync,
    selectZoomCount,
} from "../../selectors/monitoring";
import {
    deleteUnrecognizedPackagesImages,
    onClearDifferencePage,
    setDetailedDiff,
    setDifferenceStatus,
    setIsChangingDetailedDiff,
    setIsTrackDisabled,
    setLeftScale,
    setMenuOpen,
    setRightScale,
} from "../../ducks/montitoring";
import {selectUrlId} from "../../selectors/other";
import {selectComment, selectTechReportText} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<IDifferencePageStateProps, {}, State> = state => {
    const detailedDiff = selectDetailedDiff(state);
    const panelPosition = selectDifferenceBarPosition(state);
    const docPosition = selectFirstDocumentPosition(state);
    const menuOpen = selectMenuOpen(state);
    const urlId = selectUrlId(state);
    const techData = selectDetailedPair(state)?.techData

    return {
        barWidth: menuOpen ? 300 : 59,
        urlId,
        docFilterList: selectDocFilterList(state),
        isPageLoading: selectIsPageLoading(state),
        isChangingView: selectIsChangingView(state),
        isChangingDetAiledDiff: selectIsChangingDetailedDiff(state),
        leftScale: selectLeftScale(state),
        rightScale: selectRightScale(state),
        differenceData: selectDifferenceData(state),
        detailedDiff,
        imagesLeft: selectLeftImages(state),
        imagesRight: selectRightImages(state),
        scrollSync: selectScrollSync(state),
        menuOpen: menuOpen,
        panelPosition,
        docPosition,
        zoomCount: selectZoomCount(state),
        isMagnifierActive: selectMagnifierAdded(state),
        techData,
        report: selectTechReportText(state),
        detailedDocLeftId: selectDetailedDocIdLeft(state),
        comment: selectComment(state),
    }
};

const dispatchProps: MapDispatchToProps<IDifferencePageDispatchProps, {}> = {
    setDetailedDiff,
    setDifferenceStatus,
    onClearDifferencePage,
    setLeftScale,
    setRightScale,
    setIsTrackDisabled,
    deleteUnrecognizedPackagesImages,
    setIsChangingDetailedDiff,
    setMenuOpen,
};

export const DifferencePageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(DifferencePage);
