import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import {PopupElement} from "../../ducks/popup";
import {
    DifferenceType,
    IDifferenceData,
    IDiscrepancyStatus,
    ITechReport,
} from "../../api/monitoringApi";
import styles from "../../pages/DifferencePage/DifferencePage.module.scss";
import Scrollbar from "react-scrollbars-custom";
import {getDifferenceData} from "../../selectors/other";
import {IconButton, Paper} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {ThemeProvider} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {Icon} from "@iconify/react";
import deleteTextSvg from "../../pages/DifferencePage/delete_text.svg";
import {DifferenceBarPosition} from "../navigationBar/navigationBar";
import {ScrollState} from "react-scrollbars-custom/dist/types/types";
import {DifferenceContextMenu} from "../DifferenceContextMenu/DifferenceContextMenu";
import {differenceSideBarTheme} from "./theme";
import {CommentInput} from "./CommentInput";
import {setDifferenceCommentPopup} from "../../ducks/montitoring";
import {getStoreUtils} from "../../store/utils";
import {splitStringToArray} from "../../helper/other";

export interface IDifferenceListSidebarStateProps {
    differenceData: IDifferenceData[];
    detailedDiff: string | undefined;
    menuOpen: boolean;
    isChangingDetAiledDiff: boolean;
    isChangingView: boolean;
    panelPosition: string;
    docPosition: string;
    urlId: any;
    docFilterList: string[];
    barWidth: number;
    comment: string
}

export interface IDifferenceListSidebarDispatchProps {
    setDetailedDiff: (diff: string | undefined) => void;
    popupPush: (popup: PopupElement) => void;
    routeTo: (route: string | null, id?: number) => void;
    setDifferenceStatus: (index: number, status: IDiscrepancyStatus) => void;
    deleteUnrecognizedPackagesImages: (packageID: number) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
    setIsTrackDisabled: (flag: boolean) => void;
    setIsChangingDetailedDiff: (flag: boolean) => void;
    sendTechnicalReport: (props: ITechReport) => void;
    updateTechReportText: (value: string) => void;
    setDifferenceData: (data: IDifferenceData[]) => void;
    setMenuOpen: (flag: boolean) => void;
    setComment: (value: string) => void;
}
export const diff_id_symbol = 'diff-';

type DifferenceInfo = {
    marksCount: number;
    wordsCount: number;
    pagesAdded: number;
    pagesDeleted: number;
};

export type DifferenceListSidebarProps = IDifferenceListSidebarStateProps & IDifferenceListSidebarDispatchProps;
export default function DifferenceListSidebar(props: DifferenceListSidebarProps) {
    const isPanelLeft = props.panelPosition === DifferenceBarPosition.LEFT || !props.panelPosition;
    const {barWidth, detailedDiff} = props;
    const {dispatch} = getStoreUtils();
    const scrollBarRef: any = useRef();
    const [prevScrollState, setPrevScrollState] = useState<ScrollState | undefined>(undefined);
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        diffId: number;
    } | null>(null);
    const {wordsCount, marksCount, pagesAdded, pagesDeleted}: DifferenceInfo = useMemo(() => {
        const wordsCount = props.differenceData.filter((el) => [
            DifferenceType.Added,
            DifferenceType.Changed,
            DifferenceType.Deleted
        ].includes(el.type)).reduce((previousValue, currentValue) => {
            const {
                addedText,
                deletedText,
                changedTextBefore,
            } = currentValue;
            return previousValue + splitStringToArray(addedText).length + splitStringToArray(deletedText).length + splitStringToArray(changedTextBefore).length;
        }, 0);
        const marksCount = props.differenceData.reduce((acc, currentValue) => {
            return acc + currentValue.punctuationMarksCount;
        }, 0);
        return {
            wordsCount: isNaN(wordsCount) ? 0 : wordsCount,
            marksCount: isNaN(marksCount) ? 0 : marksCount,
            pagesAdded: props.differenceData.filter((el) => [
                DifferenceType.AddedPages,
            ].includes(el.type)).length ?? 0,
            pagesDeleted: props.differenceData.filter((el) => [
                DifferenceType.DeletedPages
            ].includes(el.type)).length ?? 0,

        }
    }, [props.differenceData]);

    const onSetDetailedDiff = (diff: string | undefined) => {
        if (diff) {
            props.setDetailedDiff(diff);
        }
    }
    const onClose = () => {
        setContextMenu(null);
    };

    const scrollToFitItem = (item) => {
        try {
            if (item.getBoundingClientRect().top < 0 || item.scrollHeight < item.getClientRects()[0].height) {
                item.scrollIntoView({inline: 'center', block: 'nearest'})
            }
        } catch {

        }
    }

    useLayoutEffect(() => {
        setPrevScrollState(() => (scrollBarRef.current.getScrollState()))
    }, [props.menuOpen])

    useLayoutEffect(() => {
        try {
            if (!props.isChangingView) {
                if (prevScrollState) {
                    const scrollableHeightBefore = prevScrollState.scrollHeight - prevScrollState.clientHeight;
                    const scrollPositionPercentage = (prevScrollState.scrollTop / scrollableHeightBefore) * 100;
                    const newScrollState = scrollBarRef.current.getScrollState();
                    const scrollableHeightAfter = newScrollState.scrollHeight - newScrollState.clientHeight;
                    setTimeout(() => {
                        scrollBarRef.current.scrollTo(undefined, (scrollPositionPercentage / 100) * scrollableHeightAfter);
                        const discrepancyGroup = document.getElementsByClassName(`${styles.commentsContainerItem} ${styles.selectedItem}`)?.item(0)
                        scrollToFitItem(discrepancyGroup)
                    }, 50)
                }

            }
        } catch {

        }
    }, [props.isChangingView]);
    const isCollapsed = barWidth < 150;

    return <div
        className={`${styles.commentsContainer} ${styles.commentsEmptyContainer}`}
        style={{
            paddingRight: `${isPanelLeft ? 2.5 : 0}px`,
            paddingLeft: `${!isPanelLeft ? 2.5 : 0}px`,
        }}
    >
        {(props.differenceData.length > 0 && !isCollapsed) && <div className={styles.infoText}>
            <div className={styles.infoTextRow}>
                <span>
                     Знаков изменено:
                </span>
                <span>
                    {marksCount ?? 0}
                </span>
            </div>
            <div className={styles.infoTextRow}>
                <span>
                     Слов отличается:
                </span>
                <span>
                    {wordsCount ?? 0}
                </span>
            </div>
            <div className={styles.infoTextRow}>
                <span>
                     Страниц добавлено:
                </span>
                <span>
                    {pagesAdded}
                </span>
            </div>
            <div className={styles.infoTextRow}>
                <span>
                     Страниц удалено:
                </span>
                <span>
                    {pagesDeleted}
                </span>
            </div>
        </div>}
        <Scrollbar
            ref={scrollBarRef}
            width={"100%"}
            height={"100%"}
        >
            {(props.differenceData.length === 0 && props.menuOpen) && <div className={`${styles.emptyText}`}>
                Расхождений не обнаружено
            </div>}
            {props.differenceData.length > 0 && <div className={`${styles.subContainer} ${styles.withoutBackground}`}>
                {props.differenceData.map((diff, topIndex) => {
                    const diffID = `${diff.indexFromApi}`;
                    const diffData = getDifferenceData(diff.type);
                    const width = barWidth;
                    const hide = diff.discrepancyElements && diff.discrepancyElements.length > 0 && diff.discrepancyElements.filter(discrepancy => props.docFilterList.includes(discrepancy)).length === 0;

                    const onSetIsImportant = (e: React.MouseEvent) => {
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation();

                        props.setDifferenceStatus(topIndex, {
                            important: !diff.important
                        })
                        onClose();
                    }

                    const onSetComment = (e: React.MouseEvent) => {
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation();
                        dispatch(setDifferenceCommentPopup({comment: diff.comment, detailedDiff, indexFromApi: diff.indexFromApi}));
                        onClose();
                    }

                    const handleOnDiffClick = (e: React.MouseEvent) => {
                        if (detailedDiff !== diffID) {
                            onSetDetailedDiff(diffID);
                            if (detailedDiff === diffID) {
                                scrollToFitItem(e.currentTarget)
                            }
                        }
                    }

                    const handleOnContextMenu = (event: React.MouseEvent) => {
                        if (isCollapsed) {
                            event.preventDefault()
                            setContextMenu(
                                contextMenu === null
                                    ? {
                                        mouseX: event.clientX + 5,
                                        mouseY: event.clientY + 15,
                                        diffId: diff.indexFromApi,

                                    }
                                    : null,
                            );
                        }
                    }

                    const beforeAfterText = <React.Fragment>
                        <div
                            className={`${styles.commentsContainerItemWrapperItem}`}>
                            <div className={styles.text}> <span
                                className={styles.bold}>До:</span> {diff.changedTextBefore}
                            </div>
                        </div>
                        <div
                            className={`${styles.commentsContainerItemWrapperItem}`}>
                            <div className={styles.text}>
                                <span
                                    className={styles.bold
                                }>
                                    После:
                                </span>
                                {" " + diff.changedTextAfter}
                            </div>
                        </div>
                    </React.Fragment>

                    return !hide ?<> <Paper
                        id={`${diff_id_symbol}${diffID}`}
                        tabIndex={topIndex + 1}
                        key={`${diffID}${topIndex}`}
                        elevation={0}
                        className={`${styles.commentsContainerItem}  ${(detailedDiff === diffID) && styles.selectedItem} ${isCollapsed ? styles.centerIcon : ""}`}
                        onClick={handleOnDiffClick}
                        onContextMenu={handleOnContextMenu}
                    >
                        <div
                            className={`${styles.commentsContainerItemWrapper} ${isCollapsed ? styles.wrapperNoMargin : ""}`}
                        >
                            <div
                                className={`${styles.commentsContainerItemWrapperItem} ${isCollapsed ? styles.wrapperNoMargin : ""} ${isCollapsed ? styles.centerIcon : ""} ${styles[diffData.color]}`}>
                                {width >= 150 && <div className={styles.nameRow}>
                                                <span>
                                                    {diffData.name}
                                                </span>
                                    <IconButton
                                        title={'Отметить как важное'}
                                        className={`${diff.important ? styles.pushpinIconActive : ""}`}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                        size={"small"}
                                        onClick={onSetIsImportant}>
                                        {diff.important ?
                                            <StarIcon
                                                className={styles.pushpinIcon}
                                                fontSize="inherit"
                                            /> :
                                            <StarBorderIcon
                                                className={styles.pushpinIcon}
                                                fontSize="inherit"
                                            />}
                                    </IconButton>
                                </div>}
                                {isCollapsed && <React.Fragment
                                >
                                    <ThemeProvider theme={differenceSideBarTheme(`#F2F7FA`)}>
                                        <Tooltip
                                            sx={{
                                                backgroundColor: `${diffData.colorValue} !important`,
                                            }}
                                            placement={'right'}
                                            title={<React.Fragment>
                                                <div
                                                    className={`${styles.commentsContainerItemWrapperItem} ${styles[diffData.color]}`}>
                                                    <span>{diffData.name}</span>
                                                </div>
                                                {(diff.type === DifferenceType.Added && diff.addedText) &&
                                                    <div
                                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                                        <div className={styles.text}>{diff.addedText}</div>
                                                    </div>}
                                                {(diff.type === DifferenceType.Deleted && diff.deletedText) &&
                                                    <div
                                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                                        <div className={styles.text}>{diff.deletedText}</div>
                                                    </div>}
                                                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) && beforeAfterText}
                                            </React.Fragment>}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: `100%`,
                                                }}
                                            >
                                                {diff.type === DifferenceType.Added &&
                                                    <Icon icon="tabler:text-plus" width="32" height="32"/>}
                                                {diff.type === DifferenceType.Deleted &&
                                                    <img style={{objectFit: 'contain'}} width={32} height={32}
                                                         src={deleteTextSvg} alt={''}/>}
                                                {diff.type === DifferenceType.Changed &&
                                                    <Icon icon="mdi:file-document-edit-outline"
                                                          color={`${diffData.colorValue}`} width="32"
                                                          height="32"/>}
                                                {diff.type === DifferenceType.DeletedPages &&
                                                    <Icon icon="mdi:file-document-remove-outline"
                                                          color={`${diffData.colorValue}`} width="32"
                                                          height="32"/>}
                                                {diff.type === DifferenceType.AddedPages &&
                                                    <Icon icon="mdi:file-document-plus-outline"
                                                          color={`${diffData.colorValue}`} width="32"
                                                          height="32"/>}
                                                {diff.type === DifferenceType.ChangedPages &&
                                                    <Icon icon="iconamoon:compare"
                                                          color={`${diffData.colorValue}`} width="32"
                                                          height="32"/>}
                                            </div>
                                        </Tooltip>
                                    </ThemeProvider>

                                </React.Fragment>}
                            </div>
                            {width >= 150 && <React.Fragment>
                                {(diff.type === DifferenceType.Added && diff.addedText) && <div
                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                    <div className={styles.text}>{diff.addedText}</div>
                                </div>}
                                {(diff.type === DifferenceType.Deleted && diff.deletedText) && <div
                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                    <div className={styles.text}>{diff.deletedText}</div>
                                </div>}
                                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) && beforeAfterText}
                                <div
                                    className={`${styles.commentsContainerItemWrapperItem}`}
                                >
                                    <ThemeProvider theme={differenceSideBarTheme(`#F2F7FA`)}>
                                    <CommentInput
                                        indexFromApi={diff.indexFromApi}
                                        comment={diff.comment}
                                        detailedDiff={detailedDiff}
                                    />
                                    </ThemeProvider>
                                </div>
                            </React.Fragment>}
                        </div>
                    </Paper>
                        <DifferenceContextMenu
                            onClose={onClose}
                            open={(contextMenu !== null && contextMenu.diffId === diff.indexFromApi)}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                                    : undefined
                            }
                            isImportant={diff.important}
                            setImportantHandler={onSetIsImportant}
                            setCommentHandler={onSetComment}

                        />
                    </> : <div key={`${diffID}${topIndex}`}/>
                })}
            </div>}
        </Scrollbar>
    </div>
}
