import React, {FC, useEffect, useState} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField} from "@mui/material";
import {getStoreUtils} from "../../store/utils";
import {setDifferenceComment} from "../../ducks/montitoring";

export type CommentInputProps = {
    detailedDiff: string;
    comment: string;
    indexFromApi: number;
    label?: string;
    placeholder?: string;
    minRows?: number;
}

export const CommentInputIdPrefix = 'comment-'
export const CommentInput: FC<CommentInputProps> = ({ comment, detailedDiff, indexFromApi, label = "Добавить комментарий", placeholder, minRows}) => {

    const [value, setValue] = useState<string>(comment);

    useEffect(() => {
        setValue(comment);
    }, [comment]);

    const handleCommentChange: TextFieldProps['onChange'] = (event) => {
        setValue(event?.target?.value ?? '');
    }

    return <TextField
        minRows={minRows}
        value={value}
        onChange={handleCommentChange}
        fullWidth
        label={label}
        placeholder={placeholder}
        multiline
        size="small"
        InputLabelProps={{shrink: true}}
        sx={{fontSize: '12px'}}
        margin={"normal"}
        tabIndex={-1}
        id={`${CommentInputIdPrefix}${indexFromApi}`}
        onBlur={() => {
            getStoreUtils().dispatch(setDifferenceComment(detailedDiff, value))
        }}
    />
}
