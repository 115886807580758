import { useEffect } from 'react';

type EventHandler = (e: KeyboardEvent) => void;

export default (fn?: EventHandler) => {
    useEffect(() => {
        window.addEventListener('keyup', fn as EventListener);

        return () => window.removeEventListener('keyup', fn as EventListener);
    }, [fn]);
};
